var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',{staticClass:"biller"},[_c('h3',{staticClass:"biller__header"},[_vm._v(_vm._s(_vm.billCategoryTwo.billername))]),_c('section',{staticClass:"transfer"},[_c('form',{staticClass:"form",attrs:{"novalidate":"true","autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return _vm.submitForm($event)}}},[_c('div',{staticClass:"form__group--three"},[_c('label',{staticClass:"form__label",attrs:{"for":"productType"}},[_c('select',{directives:[{name:"model",rawName:"v-model.lazy",value:(_vm.$v.form.productType.$model),expression:"$v.form.productType.$model",modifiers:{"lazy":true}}],staticClass:"form__input form__select",class:{
              'is-invalid': _vm.submitted && _vm.$v.form.productType.$error,
            },attrs:{"name":"productType","id":"productType"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.$v.form.productType, "$model", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"disabled":"","value":""}},[_vm._v("Choose a product")]),_vm._l((_vm.billSubCategory),function(product){return _c('option',{key:product.Id,domProps:{"value":product}},[_vm._v(" "+_vm._s(product.name)+" "+_vm._s(product.amount !== ".00" ? "- " + _vm.formatAmount(product.amount) : "")+" ")])})],2)]),(_vm.submitted && !_vm.$v.form.productType.required)?_c('small',{staticClass:"error"},[_vm._v("Product type is required")]):_vm._e()]),_c('div',{staticClass:"form__group--three"},[_c('label',{attrs:{"for":"number"}},[_c('input',{directives:[{name:"model",rawName:"v-model.lazy",value:(_vm.$v.form.number.$model),expression:"$v.form.number.$model",modifiers:{"lazy":true}}],staticClass:"form__input",class:{
              'is-invalid': _vm.submitted && _vm.$v.form.number.$error,
            },attrs:{"type":"tel","id":"number","name":"number","placeholder":_vm.placeholder},domProps:{"value":(_vm.$v.form.number.$model)},on:{"change":function($event){return _vm.$set(_vm.$v.form.number, "$model", $event.target.value)}}})]),(_vm.submitted && !_vm.$v.form.number.required)?_c('small',{staticClass:"error"},[_vm._v(_vm._s(_vm.placeholder)+" is required")]):_vm._e(),_c('br'),(_vm.submitted && !_vm.$v.form.number.numeric)?_c('small',{staticClass:"error"},[_vm._v("Needs to be numeric only.")]):_vm._e()]),_c('div',[_c('div',{staticClass:"form__group--three"},[_c('label',{staticClass:"form__label",attrs:{"for":"amount"}},[_c('input',{directives:[{name:"model",rawName:"v-model.lazy",value:(_vm.$v.form.amount.$model),expression:"$v.form.amount.$model",modifiers:{"lazy":true}}],staticClass:"form__input",class:{
                'is-invalid': _vm.submitted && _vm.$v.form.amount.$error,
              },attrs:{"type":"tel","id":"amount","name":"amount","placeholder":"Amount","autocorrect":"off","spellcheck":"false","autocomplete":"off","disabled":_vm.disabled},domProps:{"value":(_vm.$v.form.amount.$model)},on:{"change":[function($event){return _vm.$set(_vm.$v.form.amount, "$model", $event.target.value)},function($event){_vm.showAmounts = false}],"focus":function($event){_vm.disabled ? (_vm.showAmounts = false) : (_vm.showAmounts = true)}}})]),(_vm.submitted && !_vm.$v.form.amount.required)?_c('small',{staticClass:"error"},[_vm._v("Amount is required")]):_vm._e(),_c('br'),(_vm.submitted && !_vm.$v.form.amount.decimal)?_c('small',{staticClass:"error"},[_vm._v("Needs to be numeric only.")]):_vm._e()]),(_vm.showAmounts)?_c('app-amount-suggest',{attrs:{"type":"bills"},on:{"selectedAmount":_vm.setAmount}}):_vm._e()],1),_c('div',{staticClass:"form__group--five"},[_c('div',[_c('h4',[_vm._v("VAT + Fees:")]),_c('p',[_vm._v(" "+_vm._s(_vm.form.productType !== "" ? _vm.formatAmount(+_vm.form.productType.itemfee) : "")+" "+_vm._s(_vm.form.productType !== "" ? "&" : "")+" "+_vm._s(_vm.form.productType !== "" ? _vm.formatAmount(+_vm.form.productType.vat) : "")+" ")])]),_c('div',[_c('h4',[_vm._v("Total Amount:")]),_c('p',[_vm._v(" "+_vm._s(_vm.form.amount !== "" ? _vm.formatAmount( +_vm.form.productType.itemfee + +_vm.form.productType.vat + +_vm.form.amount ) : "")+" ")])])]),_c('div',{staticClass:"form__group--three"},[_c('label',{staticClass:"form__label",attrs:{"for":"sourceAccount"}},[_c('input',{directives:[{name:"model",rawName:"v-model.lazy",value:(_vm.$v.form.sourceAccount.$model),expression:"$v.form.sourceAccount.$model",modifiers:{"lazy":true}}],staticClass:"form__input hide-caret",class:{
              'is-invalid': _vm.submitted && _vm.$v.form.sourceAccount.$error,
            },attrs:{"readonly":"","type":"tel","id":"sourceAccount","name":"sourceAccount","placeholder":"From","autocorrect":"off","spellcheck":"false","autocomplete":"off"},domProps:{"value":(_vm.$v.form.sourceAccount.$model)},on:{"focus":function($event){_vm.showFromModal = true},"change":function($event){return _vm.$set(_vm.$v.form.sourceAccount, "$model", $event.target.value)}}})]),(_vm.submitted && !_vm.$v.form.sourceAccount.required)?_c('small',{staticClass:"error"},[_vm._v("Source account is required")]):_vm._e(),_c('img',{attrs:{"src":require("../../assets/images/down-arrow.svg"),"alt":"dropdown arrow"}})]),_c('button',{staticClass:"btn btn__red btn__group",attrs:{"type":"submit"}},[(_vm.loading)?_c('loading'):_vm._e(),_c('span',[_vm._v("Confirm")])],1)]),(_vm.showFromModal)?_c('app-source-accounts',{attrs:{"showFromModal":_vm.showFromModal},on:{"sourceDetails":_vm.setSourceDetails,"close":function($event){_vm.showFromModal = false}}}):_vm._e(),(_vm.showPinModal)?_c('app-pin-modal',{ref:"otpInput",attrs:{"showPinModal":_vm.showPinModal},on:{"close":function($event){_vm.showPinModal = false},"userPin":_vm.setUserPin}}):_vm._e(),(_vm.showOtpModal)?_c('app-otp-modal',{ref:"otpInput",attrs:{"showOtpModal":_vm.showOtpModal},on:{"close":function($event){_vm.showOtpModal = false},"otPin":_vm.setOtpValue}}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }