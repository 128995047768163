<template>
  <main class="biller">
    <h3 class="biller__header">{{ billCategoryTwo.billername }}</h3>
    <section class="transfer">
      <form
        class="form"
        @submit.prevent="submitForm"
        novalidate="true"
        autocomplete="off"
      >
        <div class="form__group--three">
          <label for="productType" class="form__label">
            <select
              name="productType"
              id="productType"
              class="form__input form__select"
              v-model.lazy="$v.form.productType.$model"
              :class="{
                'is-invalid': submitted && $v.form.productType.$error,
              }"
            >
              <option disabled value="">Choose a product</option>
              <option
                v-for="product in billSubCategory"
                :key="product.Id"
                :value="product"
              >
                {{ product.name }}
                {{ product.amount !== ".00" ? "- " + formatAmount(product.amount) : "" }}
              </option>
            </select>
          </label>
          <small class="error" v-if="submitted && !$v.form.productType.required"
            >Product type is required</small
          >
        </div>

        <div class="form__group--three">
          <label for="number">
            <input
              type="tel"
              id="number"
              name="number"
              :placeholder="placeholder"
              class="form__input"
              v-model.lazy="$v.form.number.$model"
              :class="{
                'is-invalid': submitted && $v.form.number.$error,
              }"
          /></label>
          <small class="error" v-if="submitted && !$v.form.number.required"
            >{{ placeholder }} is required</small
          >
          <br />
          <small class="error" v-if="submitted && !$v.form.number.numeric"
            >Needs to be numeric only.</small
          >
        </div>

        <div>
          <div class="form__group--three">
            <label for="amount" class="form__label">
              <input
                type="tel"
                id="amount"
                name="amount"
                placeholder="Amount"
                class="form__input"
                autocorrect="off"
                spellcheck="false"
                autocomplete="off"
                :disabled="disabled"
                @change="showAmounts = false"
                @focus="disabled ? (showAmounts = false) : (showAmounts = true)"
                v-model.lazy="$v.form.amount.$model"
                :class="{
                  'is-invalid': submitted && $v.form.amount.$error,
                }"
            /></label>
            <small class="error" v-if="submitted && !$v.form.amount.required"
              >Amount is required</small
            >
            <br />
            <small class="error" v-if="submitted && !$v.form.amount.decimal"
              >Needs to be numeric only.</small
            >
          </div>

          <app-amount-suggest
            v-if="showAmounts"
            @selectedAmount="setAmount"
            type="bills"
          ></app-amount-suggest>
        </div>

        <div class="form__group--five">
          <div>
            <h4>VAT + Fees:</h4>
            <p>
              {{ form.productType !== "" ? formatAmount(+form.productType.itemfee) : "" }}
              {{ form.productType !== "" ? "&" : "" }}
              {{ form.productType !== "" ? formatAmount(+form.productType.vat) : "" }}
            </p>
          </div>
          <div>
            <h4>Total Amount:</h4>
            <p>
              {{
                form.amount !== ""
                  ? formatAmount(
                      +form.productType.itemfee + +form.productType.vat + +form.amount
                    )
                  : ""
              }}
            </p>
          </div>
        </div>

        <div class="form__group--three">
          <label for="sourceAccount" class="form__label">
            <input
              readonly
              type="tel"
              id="sourceAccount"
              name="sourceAccount"
              placeholder="From"
              class="form__input hide-caret"
              @focus="showFromModal = true"
              autocorrect="off"
              spellcheck="false"
              autocomplete="off"
              v-model.lazy="$v.form.sourceAccount.$model"
              :class="{
                'is-invalid': submitted && $v.form.sourceAccount.$error,
              }"
          /></label>
          <small class="error" v-if="submitted && !$v.form.sourceAccount.required"
            >Source account is required</small
          >
          <img src="../../assets/images/down-arrow.svg" alt="dropdown arrow" />
        </div>

        <button type="submit" class="btn btn__red btn__group">
          <loading v-if="loading" />
          <span>Confirm</span>
        </button>
      </form>

      <app-source-accounts
        v-if="showFromModal"
        @sourceDetails="setSourceDetails"
        :showFromModal="showFromModal"
        @close="showFromModal = false"
      ></app-source-accounts>

      <app-pin-modal
        ref="otpInput"
        v-if="showPinModal"
        :showPinModal="showPinModal"
        @close="showPinModal = false"
        @userPin="setUserPin"
      ></app-pin-modal>

      <app-otp-modal
        ref="otpInput"
        v-if="showOtpModal"
        :showOtpModal="showOtpModal"
        @close="showOtpModal = false"
        @otPin="setOtpValue"
      ></app-otp-modal>
    </section>
  </main>
</template>

<script>
import api from "@/api/api.js";
import OtpModal from "@/widgets/OtpModal.vue";
import { mapState } from "vuex";
import SourceAccounts from "@/components/Transfers/Widgets/SourceAccounts.vue";
import AmountSelector from "@/components/Transfers/Widgets/AmountSelector.vue";
import PinModal from "@/widgets/PinModal.vue";

import { required, numeric, decimal } from "vuelidate/lib/validators";
export default {
  components: {
    "app-source-accounts": SourceAccounts,
    "app-amount-suggest": AmountSelector,
    "app-pin-modal": PinModal,
    "app-otp-modal": OtpModal,
  },
  computed: mapState([
    "loading",
    "userAccounts",
    "user",
    "allCategories",
    "billCategoryTwo",
    "billSubCategory",
  ]),
  data() {
    return {
      dynamicAmount: false,
      disabled: true,
      placeholder: "",
      passwordFieldType: "password",
      showFromModal: false,
      showAmounts: false,
      submitted: false,
      selectedAccount: "",
      showPinModal: false,
      showOtpModal: false,
      form: {
        productType: "",
        number: "",
        amount: "",
        sourceAccount: "",
        debitNuban: "",
        accountType: "",
        isWallet: false,
        pin: "",
      },
    };
  },
  validations: {
    form: {
      productType: { required },
      number: { required, numeric },
      amount: { required, decimal },
      sourceAccount: { required },
    },
  },
  watch: {
    selectedAccount: function (newValue) {
      if (newValue !== "") {
        this.form.sourceAccount = `${newValue.NUBAN} | ${newValue.ACCT_TYPE}`;
      }
    },
    "form.accountType": function (newValue) {
      if (newValue === "Wallet") {
        this.form.isWallet = true;
      }
    },
    "form.productType": function (newValue) {
      if (newValue.amount === ".00") {
        this.disabled = false;
        this.dynamicAmount = true;
      } else {
        this.disabled = true;
        this.dynamicAmount = false;
        this.form.amount = newValue.amount;
      }
    },
    showPinModal: function (newValue) {
      if (newValue === true) {
        this.$nextTick(() => {
          this.fixChromeMask();
        });
      }
    },
  },
  methods: {
    setAmount(value) {
      this.form.amount = value;
      this.showAmounts = false;
    },
    setSourceDetails(account) {
      this.form.debitNuban = account.NUBAN;
      this.form.accountType = account.ACCT_TYPE;
      this.selectedAccount = account;
      this.showFromModal = false;
    },
    formatAmount(digit) {
      let num;
      if (typeof digit !== "string") {
        if (typeof digit === "number") {
          num = digit;
        }
      } else num = Number.parseFloat(digit, 10);
      if (typeof num !== undefined) {
        num = num.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
        return `#${num}`;
      }
    },
    fixChromeMask() {
      // Modal mask fix for chrome
      this.$refs.otpInput.$el.children[0].setAttribute("autocomplete", "new-password");
      const inputs = this.$refs.otpInput.$el.querySelectorAll(".otp-input");
      inputs.forEach((i) => i.setAttribute("autocomplete", "new-password"));
    },
    setUserPin(value) {
      this.form.pin = value;
      this.showPinModal = false;

      let payload = {
        userId: this.user.profileInfo.SMID,
        pin: value,
      };

      api
        .handlePinValidation(payload)
        .then((response) => {
          // console.log(response, "PIN validation");
          let responseStatus = response.Status;
          let responseMessage = response.Message;
          if (responseStatus && responseMessage === "Pin Validated Successfully") {
            this.$message({
              showClose: true,
              message: `PIN VALIDATED SUCCESSFULLY`,
              type: "success",
              duration: 10000,
            });
            this.showOtpModal = true;

            if (this.user.profileInfo.iswalletonly) {
              api
                .generateWalletOtp({ phone: this.user.walletInfo.mobile })
                .then((response) => {
                  let responseStatus = response.Status;
                  if (responseStatus) {
                    this.$message({
                      showClose: true,
                      message: `ENTER THE OTP SENT TO YOUR MOBILE NUMBER`,
                      type: "success",
                      duration: 10000,
                    });
                  }
                })
                .catch((error) => {
                  this.$message({
                    showClose: true,
                    message: `${error}`,
                    type: "error",
                    duration: 10000,
                  });
                });
            } else {
              api
                .generateTransactionOtp({
                  nuban: this.user.profileInfo.PryAccount,
                })
                .then((response) => {
                  let responseStatus = response.Status;
                  if (responseStatus) {
                    this.$message({
                      showClose: true,
                      message: `ENTER THE OTP SENT TO YOUR MOBILE NUMBER`,
                      type: "success",
                      duration: 10000,
                    });
                  }
                })
                .catch((error) => {
                  this.$message({
                    showClose: true,
                    message: `${error}`,
                    type: "error",
                    duration: 10000,
                  });
                });
            }
          } else {
            this.$message({
              showClose: true,
              message: `Error validating pin`,
              type: "error",
              duration: 10000,
            });
          }
        })
        .catch((error) => {
          this.$message({
            showClose: true,
            message: `${error}`,
            type: "error",
            duration: 10000,
          });
        });
      // this.handlePayBiller();
    },
    handlePayBiller() {
      let category = this.allCategories.find(
        (category) => category.categoryid === this.form.productType.categoryid
      );

      let payload = {
        Category: category.name,
        Amt: String(this.form.amount),
        IsFromWallet: this.form.isWallet,
        Paymentcode: this.form.productType.paymentcode,
        Mobile: this.user.profileInfo.phonenumber,
        Email: this.user.profileInfo.email,
        SubscriberInfo: this.form.number,
        Nuban: this.form.debitNuban,
        TransactionPin: this.form.pin,
        SMID: this.user.profileInfo.SMID,
        BeneficiaryName: "",
        SaveBeneficiary: false,
      };

      this.$store.commit("toggleLoading", true);

      api
        .payBiller(payload)
        .then((response) => {
          let responseStatus = response.Status;
          let responseMessage = response.Message;

          if (responseStatus) {
            this.$alert(
              `${responseMessage.charAt(0).toUpperCase() + responseMessage.slice(1)}`,
              "Success",
              {
                confirmButtonText: "OK",
                type: "success",
                dangerouslyUseHTMLString: true,
              }
            );
            this.$store.dispatch("handleGetUserAccounts");
          } else {
            this.$store.commit("toggleLoading", false);
            this.$alert(
              `${responseMessage.charAt(0).toUpperCase() + responseMessage.slice(1)}`,
              "Error",
              {
                confirmButtonText: "OK",
                type: "error",
                dangerouslyUseHTMLString: true,
              }
            );
          }
        })
        .catch((error) => {
          this.$store.commit("toggleLoading", false);
          this.$message({
            showClose: true,
            message: `${error}`,
            type: "error",
            duration: 10000,
          });
        });
    },

    setOtpValue(value) {
      this.showOtpModal = false;
      this.otp = value;

      if (this.user.profileInfo.iswalletonly) {
        api
          .verifyWalletOtp({
            phone: this.user.walletInfo.mobile,
            otp: value,
          })
          .then((response) => {
            let responseStatus = response.Status;
            if (responseStatus) {
              this.handlePayBiller();
            }
          })
          .catch((error) => {
            this.$message({
              showClose: true,
              message: `${error}`,
              type: "error",
              duration: 10000,
            });
          });
      } else {
        api
          .verifyTransactionOtp({
            nuban: this.user.profileInfo.PryAccount,
            otp: value,
          })
          .then((response) => {
            // console.log(response);
            let responseStatus = response.Status;
            if (responseStatus) {
              this.handlePayBiller();
            } else {
              this.$message({
                showClose: true,
                message: `OTP could not be validated`,
                type: "error",
                duration: 10000,
              });
            }
          })
          .catch((error) => {
            this.$message({
              showClose: true,
              message: `${error}`,
              type: "error",
              duration: 10000,
            });
          });
      }
    },
    submitForm() {
      this.submitted = true;

      this.$v.form.$touch();

      if (this.$v.form.$invalid) {
        return;
      }

      this.showPinModal = true;
    },
  },
  mounted() {
    let subscriberInfoPlaceholder = this.billSubCategory[0].consumerIdfield;
    this.placeholder = subscriberInfoPlaceholder;
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_form.scss";
</style>
